<template>
    <div class="login-container">
        <div class="login-form" style="height: 300px">
            <div class="login-title">{{ title }}</div>
            <div class="login-input">
                <span class="input-title">账号</span>
                <el-input
                    placeholder="账号"
                    v-model="formFileds.username"
                    clearable
                    @keyup.enter.native="login"
                >
                </el-input>
            </div>
            <div class="login-input">
                <span class="input-title">密码</span>
                <el-input
                    placeholder="密码"
                    v-model="formFileds.password"
                    show-password
                    @keyup.enter.native="login"
                >
                </el-input>
            </div>
            <div class="login-btn">
                <el-button type="info" @click.native.prevent="login">
                    立即登录
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            title: '管理平台',
            formFileds: {
                username: '',
                password: '',
            },

            timer: null,
            isSmsSend: false,
            sendBtnText: '发送验证码',
            counter: 60,
        }
    },
    created() {
        if (process.env.VUE_APP_TITLE) {
            this.title = process.env.VUE_APP_TITLE
        }
        if (process.env.VUE_APP_LOGIN_USERNAME) {
            this.formFileds.username = process.env.VUE_APP_LOGIN_USERNAME
        }
        if (process.env.VUE_APP_LOGIN_PASSWORD) {
            this.formFileds.password = process.env.VUE_APP_LOGIN_PASSWORD
        }
    },
    methods: {
        login() {
            if (this.formFileds.username === '') {
                this.$message({
                    duration: 1000,
                    message: '请填写账号',
                    type: 'error',
                })
                return false
            }
            if (this.formFileds.password === '') {
                this.$message({
                    duration: 1000,
                    message: '请填写密码',
                    type: 'error',
                })
                return false
            }
            this.$api.login2(this.formFileds).then((result) => {
                console.log('login', result)
                let res = {}
                res.menuCurrent = ['账号信息']
                res.token = result.token
                res.userInfo = {
                    id: result.departmentId,
                    userName: result.departmentName,
                    name: result.username,
                }
                // res.menuList = result.menuList
                res.menuList = [
                    // {
                    //     name: '工会管理',
                    //     url: 'department',
                    //     children: [
                    //         {
                    //             name: '入会申请',
                    //             url: 'apply',
                    //         },
                    //     ],
                    // },
                    // {
                    //     name: '订单管理',
                    //     url: 'order',
                    //     children: [
                    //         {
                    //             name: '订单列表',
                    //             url: 'index',
                    //         },
                    //     ],
                    // },
                    {
                        name: '用户管理',
                        url: 'user',
                        children: [
                            {
                                name: '用户列表',
                                url: 'index',
                            },
                        ],
                    },
                    {
                        name: '充值管理',
                        url: 'money',
                        children: [
                            {
                                name: '充值列表',
                                url: 'index',
                            },
                        ],
                    },
                    // {
                    //     name: '商家管理',
                    //     url: 'store',
                    //     children: [
                    //         {
                    //             name: '商家列表',
                    //             url: 'index',
                    //         },
                    //     ],
                    // },
                    // {
                    //     name: '站内信管理',
                    //     url: 'message',
                    //     children: [
                    //         {
                    //             name: '站内信列表',
                    //             url: 'index',
                    //         },
                    //         {
                    //             name: '发送记录',
                    //             url: 'sendRecord',
                    //         },
                    //     ],
                    // },
                ]
                this.$store.commit('login', res)
                this.$store.commit('createRouter', this.$router)
                this.$router.push({ path: '/' })
            })
        },
        /**
         * 重置倒计时
         */
        reset() {
            this.isSmsSend = false
            this.sendBtnText = '发送验证码'
            if (this.timer) {
                clearInterval(this.timer)
                this.counter = 60
                this.timer = null
            }
        },
        /**
         * 倒计时
         */
        countDown() {
            this.timer = setInterval(() => {
                this.sendBtnText = `(${this.counter}s)后重新发送`
                this.counter--
                if (this.counter < 0) {
                    this.reset()
                }
            }, 1000)
        },
        /**
         * 发送验证码
         */
        sendSmsCode() {
            // 判断手机号是否已经输入
            if (!this.formFileds.phone) {
                this.$message({
                    duration: 1000,
                    message: '请输入手机号',
                    type: 'error',
                })
                return false
            }
            // 判断手机号是否符合要求
            if (this.formFileds.phone.length !== 11) {
                this.$message({
                    duration: 1000,
                    message: '请输入11位手机号',
                    type: 'error',
                })
                return false
            }
            this.$api
                .sendSms({
                    username: this.formFileds.username,
                    phone: this.formFileds.phone,
                })
                .then((res) => {
                    this.$message({
                        duration: 1000,
                        message: '发送成功',
                        type: 'success',
                    })
                    sessionStorage.setItem('requestId', res)
                    this.isSmsSend = true
                    this.countDown()
                })
        },
    },
}
</script>
